import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Akeid Ndulan" />
    <h3 className='underline-title'>Akeid Ndulan</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je ne sais quel magnétisme me projette vers toi<br />
        Tu es pourtant comme les autres <br />
        Non! Non!<br />
        Tu n'es pas comme les autres!<br />
        Ta peau bafoue leur crème dermique <br /><br />
        Et le grattoir méphistophélique de l'ambi <br />
        Ta peau de la nature reflet de l'Afrique sub-saharienne <br />
        Aucune de leurs mixtures n'a curé ta nature peau<br />
      </p>
      <p>
        Tes yeux globuleux au regard cristallin<br />
        Rendent diaphane mon âme affolée <br />
        L'intelligence habite tes yeux de globe <br />
        Tes yeux défient leurs yeux de crapule <br />
        Boyoma (1) baptise de "kingo mwambé" (2) ton cou d'échasse <br />
        Cou monté sur la taille de grand Flandrin <br />
      </p>
      <p>
        Oiseau à pattes insubmersibles<br />
        Privilégié marcheur sur des fonds vaseux <br />
        Oiseau céleste oiseau aquatique <br />
        Agent aux démarches aériennes<br />
      </p>
      <p>
        Sublime est l'émanation divine<br />
        Qu'exhale ton corps gracile d'échassier <br />
        Corps saturé de sex-appeal d'Aphrodite <br />
        Captivant mon coeur à tout fendre<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">
      (1) Sobriquet de Kisangani (ex-Stanleyville)<br/>
      (2) Textuellement: "huit cous" = cou mince et élancé
    </p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
